import React, { useEffect, useState } from 'react';
import { Layout, Table} from 'antd';
import Request from '../common/Request'
import { useNavigate } from "react-router-dom";


export default function (props) {
    const [datas, setDatas] = useState([]);
    const [totalSize, setTotalSize] = useState(0)
    const navigate = useNavigate();

    useEffect(() => {
        getData()
    }, [totalSize])

    function getData() {
        Request.request({
            url: '/wbs/statistics/index?fdb'
        }).then(res => {
            if (res.code !== 0) {
                navigate("/login");
            }
            setDatas(res.result.collectionInfos)
            setTotalSize(res.result.totalSize)
        })
    }


    return (
        <Layout style={{ background: 'white' }}>
            <Table rowKey="ns" dataSource={datas} style={{ marginTop: '20px' }}
                pagination={false}
                columns={[
                    {
                        title: '集合名',
                        dataIndex: 'ns'
                    },
                    {
                        title: '大小',
                        dataIndex: 'size'
                    },
                    {
                        title: '文档数',
                        dataIndex: 'count'
                    },
                    {
                        title: '所属集合',
                        dataIndex: 'collection_name'
                    },
                    {
                        title: '所属项目',
                        dataIndex: 'project_name'
                    }
                ]}>
            </Table>
        </Layout>
    )

}