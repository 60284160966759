import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import { Outlet, NavLink } from 'react-router-dom'
import {UnorderedListOutlined} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const { Content, Sider } = Layout;
let key = 0;

function NavList() {
    const items = [
        {
            key: key++,
            icon: <UnorderedListOutlined />,
            label: <NavLink to="/home/wbs">数据库</NavLink>,
        },
        {
            key: key++,
            icon: <UnorderedListOutlined />,
            label: <NavLink to="/home/project">项目管理</NavLink>,
        },
        {
            key: key++,
            icon: <UnorderedListOutlined />,
            label: <NavLink to="/home/schedule">计划任务</NavLink>,
        },
        {
            key: key++,
            icon: <UnorderedListOutlined />,
            label: <NavLink to="/home/ssl">ssl证书</NavLink>,
        }
    ];

    return items;
}


export default function (props) {
    const navigate = useNavigate();

    const [navlist, setNavlist] = useState(NavList());
    const [collapsed, setCollapsed] = useState(false);
    function logout() {
        localStorage.removeItem('code');
        localStorage.removeItem('base64_file');
        navigate("/");
    }


    return (
        <Layout>
            <Layout>
                <Sider width={160} style={{ background: 'white', minHeight: '500px'}} collapsed={collapsed} collapsible={true} collapsedWidth={0} trigger={null}>
                    <Menu mode="inline" items={navlist}/>
                    <Button danger block onClick={logout} style={{ marginTop: '50px' }}>退出登录</Button>
                </Sider>
                <Layout>
                    <Content style={{ background: 'white', padding: '20px' }}>
                        <Outlet context={[collapsed, setCollapsed]}/>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )

}