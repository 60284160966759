import React, { useEffect, useState } from 'react';
import { Button, Layout, Modal, Form, Input, Table, message, Space,Popconfirm } from 'antd';
import { useNavigate } from "react-router-dom";
import Request from '../common/Request'
const { TextArea } = Input;
export default function (props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [gits, setGits] = useState([]);
    const [current, setCurrent] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate();


    const [formObj] = Form.useForm()

    useEffect(() => {
        getData()
    }, [current, pageSize])

    function getData() {
        Request.request({
            url: '/skynet/ssl/index?fdb',
            data: {
                page: current,
                pageSize
            }
        }).then(res => {
            if (res.code !== 0) {
                navigate("/login");
            }
            setGits(res.result.datas)
            setTotal(res.result.total)
        })
    }

    function show(record) {
        console.log(record)
        formObj.setFieldsValue(record)
        setShowModal(true)
    }
    function start(record) {
        Request.request({
            url: '/skynet/ssl/start?fdb',
            data: { "id": record._id }
        }).then(res => {
            getData();
            messageApi.open({
                type: 'success',
                content: '提交成功！',
            });
        })
    }
    function remove(record) {
        Request.request({
            url: '/skynet/ssl/remove',
            data: { "id": record._id }
        }).then(res => {
            getData();
            messageApi.open({
                type: 'success',
                content: '提交成功！',
            });
        })
    }
    return (
        <>{contextHolder}
        <Layout style={{ background: 'white' }}>
            <Table rowKey="_id" dataSource={gits} style={{ marginTop: '20px' }}
                pagination={{
                    current,
                    pageSize,
                    total,
                    position: ['bottomCenter'],
                    onChange: (current, pageSize) => {
                        setCurrent(current)
                        setPageSize(pageSize)
                    },
                }}
                columns={[
                    {
                        title: 'name',
                        dataIndex: 'name'
                    },
                    {
                        title: '签发时间',
                        dataIndex: 'issue_time'
                    },
                    {
                        title: '过期时间',
                        dataIndex: 'expiration_time'
                    },
                    {
                        title: '备注',
                        dataIndex: 'remark'
                    },
                    {
                        title: '状态',
                        dataIndex: 'state'
                    },
                    {
                        title: '操作',
                        render: (_, record) => {
                            return <Space>
                                <Button onClick={() => { show(record) }}>查看</Button>
                                {/* <Button onClick={() => { start(record) }}>部署</Button> */}
                            </Space>
                        }
                    }
                ]}>
            </Table>



            <Modal open={showModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                 form={formObj}
                >
                    <Form.Item
                        label="名称"
                        name="name"
                        rules={[{ required: true }]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="公钥"
                        name="public_key"
                        rules={[{ required: true }]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        label="私钥"
                        name="private_key"
                        rules={[{ required: true }]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setShowModal(false)}>关闭</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
        </>
    )

}