import React from "react";
import Request from '../common/Request'
import { useNavigate } from "react-router-dom";

let is_login = false;
let lock = false;
let refreshCode = true;

export default function (props) {
    const [code, setCode] = React.useState('')
    const [base64, setBase64] = React.useState('');
    const navigate = useNavigate();

    React.useEffect(() => {
        is_login = false
        setCode(localStorage.getItem("code"));
        setBase64(localStorage.getItem("base64_file"))
        timer();
    }, [])

    function timer() {
        if (is_login) return;
        checkLogin();
        setTimeout(timer, 3000);
    }

    function checkLogin() {
        if (lock) return
        lock = true
        Request.request({
            url: '/login/user/check-login?fdb',
            data: {
                'code': code,
                'refreshCode': refreshCode,
            }
        }).then(res => {
            lock = false
            if (res.result.state === '登录成功') {
                is_login = true;
                navigate("/home");
            } else if (res.result.state === 'QRCode需要更新') {
                is_login = false;
                refreshCode = false;
                console.log(res.result)
                setCode(res.result.QRCode.code);
                setBase64(res.result.QRCode.base64_file)
                localStorage.setItem("code", res.result.QRCode.code)
                localStorage.setItem("base64_file", res.result.QRCode.base64_file)
            } else {
                is_login = false;
            }
        })
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div style={{
                    textAlign: 'center',
                    margin: '40px',
                    fontSize: '30px'
                }}>请微信扫码登录</div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row'
                }}>
                    <img src={base64} style={{ with: '300px', height: '300px' }} />
                </div>
                <div style={{
                    textAlign: 'center',
                    margin: '20px',
                    fontSize: '10px'
                }}>提示：请使用Chrome或Edge浏览器，本系统未在其他浏览器上测试过</div>
            </div>
        </div>
    )
}
