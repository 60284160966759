import React, { useEffect, useState } from 'react';
import { Button, Layout, Switch, Input, Form, Table, Dropdown, Space, message, Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import Request from '../common/Request'
import { CopyToClipboard } from 'react-copy-to-clipboard';
const { TextArea } = Input;

let select_project_id = ''
export default function (props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [gits, setGits] = useState([]);
    const [diffModal, setDiffModal] = useState(false);
    const [createProjectModal, setCreateProjectModal] = useState(false);
    const [diffContent, setDiffContent] = useState([]);
    const [current, setCurrent] = useState(1)
    const [wbsChangeTime, setWbsChangeTime] = useState('')
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const navigate = useNavigate();

    const [formObj] = Form.useForm()

    useEffect(() => {
        getData()
    }, [current, pageSize])

    function getData() {
        Request.request({
            url: '/skynet/project/index?fdb',
            data: {
                page: current,
                pageSize
            }
        }).then(res => {
            if (res.code !== 0) {
                navigate("/login");
            }
            setGits(res.result.datas)
            setWbsChangeTime(res.result.wbs_demo_change_time)
            setTotal(res.result.total)
        })
    }

    function createProject(row) {
        Request.request({
            url: '/skynet/project/create?fdb',
            data: { ...row, project_id: select_project_id }
        }).then(res => {
            if (res.code) {
                // navigate("/login");
            }
            getData()
            setCreateProjectModal(false)
        })
    }
    function diff() {
        Request.request({
            url: '/skynet/project/diff?fdb',
            data: {
                project_id: select_project_id
            }
        }).then(res => {
            setDiffContent(res.result)
            setDiffModal(true)
        })
    }

    function publish() {
        Request.request({
            url: '/skynet/project/publish?fdb',
            data: {
                project_id: select_project_id
            }
        }).then(res => {
            setDiffModal(false)
            getData()
        })
    }


    function diffwbs() {
        select_project_id = 'wbs'
        Request.request({
            url: '/skynet/project/diff?fdb',
            data: {
                project_id: 'wbs'
            }
        }).then(res => {
            setDiffContent(res.result)
            setDiffModal(true)
        })
    }

    function reloadNginx() {
        Request.request({
            url: '/skynet/other/reload-nginx',
        }).then(res => {
            messageApi.open({
                type: 'success',
                content: '提交成功！',
            });
        })
    }
    function clearLogFile() {
        Request.request({
            url: '/skynet/other/clear-logfile',
        }).then(res => {
            messageApi.open({
                type: 'success',
                content: '提交成功！',
            });
        })
    }

    return (
        <Layout style={{ background: 'white' }}>
            <Space direction='vertical'>
            <Space>
                <Button onClick={() => {
                    select_project_id = ''
                    setCreateProjectModal(true)
                }} type="primary" >新增</Button>
                <Button onClick={() => { reloadNginx() }} type="primary" >重新加载Nginx配置</Button>
                <Button onClick={() => { clearLogFile() }} type="primary" >清理日志文件</Button>

                <span>WBS项目：{wbsChangeTime}</span>

                <Button onClick={() => { diffwbs() }} type="primary" >查看wbs变更</Button>
                <CopyToClipboard text={'git clone git@git.code.tencent.com:bofeilandao/wbs.git'}>
                    <Button>Copy代码</Button>
                </CopyToClipboard>
            </Space>
            <Space>
                <a href="https://git.code.tencent.com/" target="_blank">腾讯工蜂</a>
                <CopyToClipboard text={'http://wbs.fivecakes.com/webhook/githook/index'}>
                    <Button>复制web hooks地址</Button>
                </CopyToClipboard>
                <CopyToClipboard text={'git clone git@git.code.tencent.com:bofeilandao/dockerfile.git'}>
                    <Button>复制docker file地址</Button>
                </CopyToClipboard>
            </Space>
            </Space>
            <Table rowKey="_id" dataSource={gits} style={{ marginTop: '20px' }}
                pagination={{
                    current,
                    pageSize,
                    total,
                    position: ['bottomCenter'],
                    onChange: (current, pageSize) => {
                        setCurrent(current)
                        setPageSize(pageSize)
                    },
                }}
                columns={[
                    {
                        title: '地址',
                        render: (_, record) => {
                            return <Space direction='vertical'>
                                <a href={'http://' + record.demo_url} target="_blank">{record.demo_url}</a>
                                <a href={'http://' + record.produce_url} target="_blank">{record.produce_url}</a>
                            </Space>
                        }
                    },
                    {
                        title: '代码',
                        render: (_, record) => {
                            return <CopyToClipboard text={'git clone git@git.code.tencent.com:bofeilandao/' + record.en_name + '.git'}>
                                <Button>Copy</Button>
                            </CopyToClipboard>
                        }
                    },
                    {
                        title: 'demo变更',
                        dataIndex: 'demo_change_time'
                    },
                    {
                        title: '操作',
                        render: (_, record) => {
                            return <Space direction='vertical'>
                                <Space >
                                    <Button onClick={() => {
                                        select_project_id = record.project_id
                                        diff()
                                    }}>查看变更</Button>
                                </Space>
                                {/* <Space >
                                    <Button>回滚</Button>
                                </Space> */}
                            </Space>
                        }
                    }
                ]}>
            </Table>
            <Modal open={diffModal} closable={false} onCancel={() => { setDiffModal(false) }} onOk={() => { publish() }} >
                {diffContent.map((item, key) => {
                    return <span key={key}>{item}</span>
                })}
            </Modal>
            <Modal open={createProjectModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={createProject} form={formObj}
                >
                    <Form.Item
                        label="项目名称"
                        name="name"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="项目代号"
                        name="en_name"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remark"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setCreateProjectModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
    )

}