import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Layout, Checkbox, Select, Row, Col, Form, Table, Dropdown, Space, Modal, Input, message, InputNumber, Popconfirm } from 'antd';
import { useNavigate, NavLink } from "react-router-dom";
import Request from '../common/Request'
import { DownOutlined, RedoOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";

let nameConfirmFlag = ''
let global_project_id = ''
let global_collection_id = ''
let select_schema_id = ''
let select_index_id = ''
let select_document_ids = []
let document_info = {}
let query = {}
let fuzzySchema = {}
let emptySchema = {}

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item style={{ margin: 0 }} name={dataIndex} >
                <TextArea ref={inputRef}  onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                    minHeight: '20px',
                    minWidth: '20px',
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

export default function (props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [projects, setProjects] = useState([]);
    const [project_id, setProject_id] = useState('');
    const [collections, setCollections] = useState([]);
    const [collection_id, setCollection_id] = useState('');
    const [nameConfirmModal, setNameConfirmModal] = useState(false);
    const [copySchemaModal, setCopySchemaModal] = useState(false);
    const [manageSchemasModal, setManageSchemasModal] = useState(false);
    const [manageIndexsModal, setManageIndexsModal] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const [newSchemaModal, setNewSchemaModal] = useState(false);
    const [newIndexModal, setNewIndexModal] = useState(false);
    const [newDocumentModal, setNewDocumentModal] = useState(false);
    const [editDocumentModal, setEditDocumentModal] = useState(false);
    const [collectionSchemas, setCollectionSchemas] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [indexs, setIndexs] = useState([]);
    const [current, setCurrent] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)


    const navigate = useNavigate();
    const [formObj] = Form.useForm()
    const [searchformObj] = Form.useForm()

    useEffect(() => {
        getProjects()
        global_project_id = ''
        global_collection_id = ''
        select_schema_id = ''
        select_index_id = ''
        select_document_ids = []
    }, [])
    useEffect(() => {
        getDocuments()
    }, [current, pageSize])

    function getProjects() {
        Request.request({
            url: '/wbs/project/get-all?fdb'
        }).then(res => {
            if (res.code !== 0) {
                navigate("/login");
            }
            setProjects(res.result)
            getCollections()
        })
    }
    function getIndexs() {
        Request.request({
            url: '/wbs/index/index?fdb',
            data:{
                collection_id: global_collection_id
            }
        }).then(res => {
            if (res.code) {
                // navigate("/login");
            }
            setIndexs(res.result)
        })
    }
    
    function getCollections() {
        if (!global_project_id) return
        Request.request({
            url: '/wbs/collection/index?fdb',
            data: {
                project_id: global_project_id,
                num: 100
            }
        }).then(res => {
            console.log(res)
            setCollection_id('')
            setCollections(res.result)
        })
    }
    function getCollectionSchema() {
        if (!global_collection_id) return
        Request.request({
            url: '/wbs/schema/get-collection-schema?fdb',
            data: {
                collection_id: global_collection_id
            }
        }).then(res => {
            console.log(res)
            setCollectionSchemas(res.result)
        })
    }
    function getDocuments() {
        if (!global_collection_id) return
        console.log({
            collection_id: global_collection_id,
            page: current,
            pageSize,
            query
        })
        Request.request({
            url: '/wbs/document/index?fdb',
            data: {
                collection_id: global_collection_id,
                page: current,
                pageSize,
                query
            }
        }).then(res => {
            console.log(res)
            setDocuments(res.result.datas)
            setTotal(res.result.total)
        })
    }
    function showDocument() {
        if (!global_collection_id) return
        if (select_document_ids.length > 1) {
            messageApi.open({
                type: 'error',
                content: '一次只能编辑一条记录!',
            });
            return
        }
        Request.request({
            url: '/wbs/document/show',
            data: {
                collection_id: global_collection_id,
                document_id: select_document_ids[0],
            }
        }).then(res => {
            console.log(res)
            formObj.setFieldsValue(res.result)
            document_info = res.result
            setEditDocumentModal(true)
        })
    }

    function nameConfirm({ name }) {
        if (nameConfirmFlag == "newCollection") {
            Request.request({
                url: '/wbs/collection/save',
                data: {
                    project_id: global_project_id,
                    name: name,
                }
            }).then(res => {
                setNameConfirmModal(false)
                getCollections()
            })
        } else if (nameConfirmFlag == "renameCollection") {
            Request.request({
                url: '/wbs/collection/save',
                data: {
                    project_id: global_project_id,
                    name: name,
                    _id: global_collection_id
                }
            }).then(res => {
                setNameConfirmModal(false)
                getCollections()
            })
        } else if (nameConfirmFlag == "deleteCollection") {
            Request.request({
                url: '/wbs/collection/remove',
                data: {
                    collection_id: global_collection_id,
                    collection_name: name
                }
            }).then(res => {
                setNameConfirmModal(false)
                getCollections()
            })
        } else if (nameConfirmFlag == "clearCollection") {
            Request.request({
                url: '/wbs/document/clear-table?fdb',
                data: {
                    collection_id: global_collection_id,
                    collection_name: name
                }
            }).then(res => {
                setNameConfirmModal(false)
                getDocuments()
            })
        } else if (nameConfirmFlag == "demo2pro") {
            Request.request({
                url: '/wbs/collection/demo2pro?fdb',
                data: {
                    collection_id: global_collection_id,
                    collection_name: name
                }
            }).then(res => {
                setNameConfirmModal(false)
                messageApi.open({
                    type: 'success',
                    content: '操作成功！',
                });
            })
        } else if (nameConfirmFlag == "pro2demo") {
            Request.request({
                url: '/wbs/collection/pro2demo?fdb',
                data: {
                    collection_id: global_collection_id,
                    collection_name: name
                }
            }).then(res => {
                setNameConfirmModal(false)
                messageApi.open({
                    type: 'success',
                    content: '操作成功！',
                });
            })
        }
    }
    function newSchema({ name, zh_name, schema_type, remark, sort, visible }) {
        Request.request({
            url: '/wbs/schema/save',
            data: {
                collection_id: global_collection_id,
                name,
                zh_name,
                schema_type,
                remark,
                sort,
                visible
            }
        }).then(res => {
            setNewSchemaModal(false)
            getCollectionSchema()
        })
    }
    function newIndex({ keys}) {
        Request.request({
            url: '/wbs/index/create',
            data: {
                collection_id: global_collection_id,
                keys
            }
        }).then(res => {
            setNewIndexModal(false)
            getIndexs()
        })
    }
    function copySchema({ from_collection_id }) {
        Request.request({
            url: '/wbs/schema/copy-schema',
            data: {
                collection_id: global_collection_id,
                from_collection_id
            }
        }).then(res => {
            setCopySchemaModal(false)
            getCollectionSchema()
        })
    }

    function deleteSchema() {
        if (!select_schema_id) return
        Request.request({
            url: '/wbs/schema/delete',
            data: {
                id: select_schema_id
            }
        }).then(res => {
            getCollectionSchema()
        })
    }
    function deleteIndex() {
        if (!select_index_id) return
        Request.request({
            url: '/wbs/index/delete',
            data: {
                collection_id: global_collection_id,
                index_name: select_index_id
            }
        }).then(res => {
            getIndexs()
        })
    }
    function deleteDocument() {
        if (!select_document_ids) return
        if (!global_collection_id) return
        Request.request({
            url: '/wbs/document/delete?fdb',
            data: {
                collection_id: global_collection_id,
                ids: select_document_ids
            }
        }).then(res => {
            getDocuments()
        })
    }


    const search = (row) => {
        console.log(row)
        query = {}
        Object.keys(row).forEach(function (key) {
            if (key.startsWith("opt-")) return
            if (row[key]) {
                query[key] = {
                    'text': row[key],
                    'fuzzy': '0',
                    'empty': '0',
                }
            }
        })
        Object.keys(fuzzySchema).forEach(function (key) {
            if (!query[key]) {
                query[key] = {
                    'text': "",
                    'fuzzy': '0',
                    'empty': '0',
                }
            }
            if (fuzzySchema[key]) {
                query[key].fuzzy = '1'
            }
        })
        Object.keys(emptySchema).forEach(function (key) {
            if (!query[key]) {
                query[key] = {
                    'text': "",
                    'fuzzy': '0',
                    'empty': '0',
                }
            }
            if (emptySchema[key]) {
                query[key].empty = '1'
            }
        })
        console.log(query)
        setSearchModal(false)
        setCurrent(1)
        getDocuments()
    };
    const editSchema = (row) => {
        Request.request({
            url: '/wbs/schema/save',
            data: row
        }).then(res => {
            getCollectionSchema()
        })
    };
    const clickFuzzy = (e) => {
        if (e.target.checked) {
            fuzzySchema[e.target['schema-name']] = 1
        } else {
            fuzzySchema[e.target['schema-name']] = 0
        }
    }
    const clickEmpty = (e) => {
        if (e.target.checked) {
            emptySchema[e.target['schema-name']] = 1
        } else {
            emptySchema[e.target['schema-name']] = 0
        }
    }
    const editDocument = (row) => {
        let data = {
            collection_id: global_collection_id,
            data: row
        }
        console.log(data)
        Request.request({
            url: '/wbs/document/save',
            data: data
        }).then(res => {
            if (res.code) {
                messageApi.open({
                    type: 'error',
                    content: res.result,
                });
            }
            getDocuments()
            setNewDocumentModal(false)
        })
    };
    const editDocument2 = (row) => {
        let data = {
            collection_id: global_collection_id,
            data: Object.assign(document_info, row)
        }
        console.log(data)
        Request.request({
            url: '/wbs/document/save',
            data: data
        }).then(res => {
            if (res.code) {
                messageApi.open({
                    type: 'error',
                    content: res.result,
                });
            }
            getDocuments()
            setEditDocumentModal(false)
        })
    };



    return (
        <Layout>
            {contextHolder}
            <Layout style={{ background: 'white' }}>
                <Row>
                    <Space >
                        <div>
                            项目：
                            <Select style={{ width: 300 }} value={project_id} onChange={(value) => {
                                setProject_id(value)
                                global_project_id = value;
                                getCollections()
                            }}>
                                {projects.map((item) => {
                                    return <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>;
                                })}
                            </Select>
                        </div>
                        <div>
                            集合：
                            <Select style={{ width: 300 }} value={collection_id} onChange={(value) => {
                                setCollection_id(value)
                                global_collection_id = value;
                                getCollectionSchema()
                                getDocuments()
                            }}>
                                {collections.map((item) => {
                                    return <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>;
                                })}
                            </Select>
                        </div>
                        <Dropdown
                            menu={{
                                items: [
                                    { key: '新增集合', label: '新增集合' },
                                    { key: '重命名集合', label: '重命名集合' },
                                    { key: '属性管理', label: '属性管理' },
                                    { key: '索引管理', label: '索引管理' },
                                    { key: '复制属性', label: '复制属性' },
                                    { key: '正式2demo', label: '正式2demo' },
                                    { key: 'demo2正式', label: 'demo2正式' },
                                    { key: '清空该表', label: '清空该表' },
                                    { key: '删除集合', label: '删除集合' },
                                ],
                                onClick: function ({ key }) {
                                    switch (key) {
                                        case '新增集合':
                                            if (!global_project_id) {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: '请先选择项目!',
                                                });
                                                return
                                            }
                                            nameConfirmFlag = "newCollection"
                                            setNameConfirmModal(true)
                                            break;
                                        case '重命名集合':
                                            if (!global_collection_id) {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: '请先选择集合！',
                                                });
                                                return
                                            }
                                            nameConfirmFlag = "renameCollection"
                                            setNameConfirmModal(true)
                                            break;
                                        case '属性管理':
                                            if (!global_collection_id) {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: '请先选择集合！',
                                                });
                                                return
                                            }
                                            setManageSchemasModal(true)
                                            break;
                                        case '索引管理':
                                            if (!global_collection_id) {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: '请先选择集合！',
                                                });
                                                return
                                            }
                                            setManageIndexsModal(true);
                                            getIndexs();
                                            break;
                                        case '复制属性':
                                            if (!global_collection_id) {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: '请先选择集合！',
                                                });
                                                return
                                            }
                                            setCopySchemaModal(true)
                                            break;
                                        case '正式2demo':
                                            if (!global_collection_id) {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: '请先选择集合！',
                                                });
                                                return
                                            }
                                            nameConfirmFlag = "pro2demo"
                                            setNameConfirmModal(true)
                                            break;
                                        case 'demo2正式':
                                            if (!global_collection_id) {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: '请先选择集合！',
                                                });
                                                return
                                            }
                                            nameConfirmFlag = "demo2pro"
                                            setNameConfirmModal(true)
                                            break;
                                        case '清空该表':
                                            if (!global_collection_id) {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: '请先选择集合！',
                                                });
                                                return
                                            }

                                            nameConfirmFlag = "clearCollection"
                                            setNameConfirmModal(true)
                                            break;
                                        case '删除集合':
                                            if (!global_collection_id) {
                                                messageApi.open({
                                                    type: 'error',
                                                    content: '请先选择集合！',
                                                });
                                                return
                                            }
                                            nameConfirmFlag = "deleteCollection"
                                            setNameConfirmModal(true)
                                            break;

                                        default:
                                            break;
                                    }
                                }
                            }}
                        >
                            <Button>集合相关操作 <DownOutlined /></Button>
                        </Dropdown>

                        <NavLink to="/home/storage"><Button>存储空间</Button></NavLink>
                        <NavLink to="/home/slow-query"><Button>慢查询</Button></NavLink>
                        <NavLink to="/home/wbs-projects"><Button>项目管理</Button></NavLink>
                    </Space>

                </Row>
                {collection_id ?
                    <>
                        <Row style={{ marginTop: '10px' }}>
                            <Space>
                                <Button onClick={() => { getDocuments() }}><RedoOutlined /></Button>
                                <Dropdown
                                    menu={{
                                        items: [
                                            { key: '新增记录', label: '新增记录' },
                                            { key: '编辑记录', label: '编辑记录' },
                                            { key: '删除记录', label: '删除记录' },
                                        ],
                                        onClick: function ({ key }) {
                                            switch (key) {
                                                case '新增记录':
                                                    if (!global_collection_id) {
                                                        messageApi.open({
                                                            type: 'error',
                                                            content: '请先选择集合!',
                                                        });
                                                        return
                                                    }
                                                    formObj.resetFields()
                                                    setNewDocumentModal(true)
                                                    break;
                                                case '编辑记录':
                                                    showDocument()
                                                    break;
                                                case '删除记录':
                                                    deleteDocument()
                                                    break;

                                                default:
                                                    break;
                                            }
                                        }
                                    }}
                                >
                                    <Button>记录相关操作 <DownOutlined /></Button>
                                </Dropdown>
                                <Button onClick={() => { setSearchModal(true) }}>搜索</Button>
                                <span style={{ lineHeight: '30px' }} >集合id:{collection_id}</span>

                            </Space>
                        </Row></>
                    : null}
                <Table rowKey="_id" dataSource={documents} style={{ marginTop: '20px' }}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (selectedRowKeys, selectedRows) => {
                            select_document_ids = selectedRowKeys;
                        }
                    }}
                    pagination={{
                        current,
                        pageSize,
                        total,
                        position: ['bottomCenter'],
                        onChange: (current, pageSize) => {
                            setCurrent(current)
                            setPageSize(pageSize)
                        }
                    }}>
                    {collectionSchemas.map((item) => {
                        return item.visible === '是' ? <Table.Column key={item._id} title={item.zh_name} dataIndex={item.name} editable={true}
                            onCell={(record) => ({
                                record,
                                editable: true,
                                dataIndex: item.name,
                                title: item.zh_name,
                                handleSave: editDocument,
                            })}></Table.Column> : null
                    })}
                </Table>
            </Layout>
            <Modal open={nameConfirmModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={nameConfirm}
                >
                    <Form.Item
                        label="名称"
                        name="name"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setNameConfirmModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal open={copySchemaModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={copySchema}
                >
                    <Form.Item
                        label="要复制属性的集合id"
                        name="from_collection_id"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setCopySchemaModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
            
            <Modal open={newSchemaModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={newSchema}
                >
                    <Form.Item
                        label="英文名"
                        name="name"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="中文名"
                        name="zh_name"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="类型"
                        name="schema_type"
                        rules={[{ required: true }]}
                    >
                        <Select options={[
                            {
                                value: '文本',
                                label: '文本',
                            },
                            {
                                value: '整型数字',
                                label: '整型数字',
                            },
                            {
                                value: '浮点型数字',
                                label: '浮点型数字',
                            },
                            {
                                value: '数组',
                                label: '数组',
                            },
                            {
                                value: '内嵌文档',
                                label: '内嵌文档',
                            }
                        ]} />
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remark"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="权重"
                        name="sort"
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label="列表显示"
                        name="visible"
                    >

                        <Select options={[
                            {
                                value: '是',
                                label: '是',
                            },
                            {
                                value: '否',
                                label: '否',
                            }
                        ]} />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setNewSchemaModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal open={newIndexModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={newIndex}
                >
                    <Form.Item
                        label="索引"
                        name="keys"
                        rules={[{ required: true }]}
                    >
                        <TextArea />
                    </Form.Item>
                    
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setNewIndexModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal open={manageSchemasModal} footer={null} onCancel={() => { setManageSchemasModal(false) }} width={1200}>
                <Space>
                    <Button onClick={() => setNewSchemaModal(true)}>新增</Button>
                    <Popconfirm
                        title="确认删除？"
                        onConfirm={deleteSchema}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger >删除</Button>

                    </Popconfirm>
                </Space>
                <Table rowKey="_id" dataSource={collectionSchemas} style={{ marginTop: '20px' }}
                    rowSelection={{
                        type: 'radio',
                        onChange: (selectedRowKeys, selectedRows) => {
                            select_schema_id = selectedRowKeys[0];
                        }
                    }}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    pagination={false}
                    columns={[
                        {
                            title: '英文名',
                            dataIndex: 'name',
                            editable: true,
                        },
                        {
                            title: '中文名',
                            dataIndex: 'zh_name',
                            editable: true,
                        },
                        {
                            title: '类型',
                            dataIndex: 'schema_type',
                            editable: true,
                        },
                        {
                            title: '备注',
                            dataIndex: 'remark',
                            editable: true,
                        },
                        {
                            title: '权重',
                            dataIndex: 'sort',
                            editable: true,
                        },
                        {
                            title: '列表显示',
                            dataIndex: 'visible',
                            editable: true,
                        }
                    ].map((col) => {
                        if (!col.editable) {
                            return col;
                        }
                        return {
                            ...col,
                            onCell: (record) => ({
                                record,
                                editable: col.editable,
                                dataIndex: col.dataIndex,
                                title: col.title,
                                handleSave: editSchema,
                            }),
                        };
                    })}>
                </Table>
            </Modal>
            <Modal open={manageIndexsModal} footer={null} onCancel={() => { setManageIndexsModal(false) }} width={1200}>
                <Space>
                    <Button onClick={() => setNewIndexModal(true)}>新增</Button>
                    <Popconfirm
                        title="确认删除？"
                        onConfirm={deleteIndex}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger >删除</Button>

                    </Popconfirm>
                </Space>
                <Table rowKey="name" dataSource={indexs} style={{ marginTop: '20px' }}
                    rowSelection={{
                        type: 'radio',
                        onChange: (selectedRowKeys, selectedRows) => {
                            select_index_id = selectedRowKeys[0];
                        }
                    }}
                    pagination={false}
                    columns={[
                        {
                            title: '索引',
                            dataIndex: 'name',
                            editable: true,
                        },
                        {
                            title: 'key',
                            dataIndex: 'key',
                            editable: true,
                        },
                    ]}>
                </Table>
            </Modal>

            <Modal open={newDocumentModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={editDocument}
                >
                    {collectionSchemas.map((item) => {
                        return <Form.Item
                            key={item._id}
                            label={item.zh_name}
                            name={item.name}
                        >
                            <TextArea />
                        </Form.Item>
                    })}
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setNewDocumentModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal open={editDocumentModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={editDocument2} form={formObj}
                >
                    {collectionSchemas.map((item) => {
                        return <Form.Item
                            key={item._id}
                            label={item.zh_name}
                            name={item.name}
                        >
                            <TextArea />
                        </Form.Item>
                    })}
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setEditDocumentModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal open={searchModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={search} form={searchformObj}
                >
                    <Row>
                        <Col span={18}>
                            <Form.Item label="_id" name="_id" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ paddingLeft: '20px' }}>
                        </Col>
                    </Row>
                    {collectionSchemas.map((item) => {
                        return <Row key={item._id}>
                            <Col span={18}>
                                <Form.Item
                                    label={item.zh_name}
                                    name={item.name}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6} style={{ paddingLeft: '20px' }}>
                                <Form.Item name={"opt-fuzzy-" + item.name} noStyle>
                                    <Checkbox schema-name={item.name} onChange={clickFuzzy}>模糊</Checkbox>
                                </Form.Item>
                                <Form.Item name={"opt-empty-" + item.name} noStyle>
                                    <Checkbox schema-name={item.name} onChange={clickEmpty}>空</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    })}
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button type="primary" style={{ background: "#0BBF5B" }} onClick={() => {
                                query = {}
                                fuzzySchema = {}
                                emptySchema = {}
                                searchformObj.resetFields();
                            }}>清空</Button>
                            <Button onClick={() => setSearchModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
    )

}