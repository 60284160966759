import React, { useEffect, useState } from 'react';
import { Layout, Table, Space, Button, Modal, Form, Input } from 'antd';
import Request from '../common/Request'
import { useNavigate } from "react-router-dom";


export default function (props) {
    const [projects, setProjects] = useState([]);
    const [editProjectModal, setEditProjectModal] = useState(false);
    const navigate = useNavigate();

    const [form] = Form.useForm();
    useEffect(() => {
        getProjects()
    }, [])


    function getProjects() {
        Request.request({
            url: '/wbs/project/get-all?fdb'
        }).then(res => {
            if (res.code !== 0) {
                navigate("/login");
            }
            setProjects(res.result)
        })
    }

    function editProject(row) {
        Request.request({
            url: '/wbs/project/save?fdb',
            data: row
        }).then(res => {
            if (res.code) {
                // navigate("/login");
            }
            getProjects()
            setEditProjectModal(false)
        })
    }


    return (
        <Layout style={{ background: 'white' }}>
            <Space  ><Button onClick={() => {
                form.setFieldsValue({ _id: "", name: "" });
                setEditProjectModal(true)
            }}  type="primary" >新增</Button></Space>
            <Table rowKey="_id" dataSource={projects} style={{ marginTop: '20px' }}
                pagination={false}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name'
                    },
                    {
                        title: '操作',
                        render: (_, record) => {
                            return <Space direction='vertical'>
                                <Button onClick={() => {
                                    console.log(record)
                                    form.setFieldsValue({ _id: record._id, name: record.name });
                                    setEditProjectModal(true)
                                }}>编辑</Button>
                            </Space>
                        }
                    }
                ]}>
            </Table>
            <Modal open={editProjectModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={editProject} form={form}
                >
                    <Form.Item
                        label="_id"
                        name="_id"
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="名称"
                        name="name"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setEditProjectModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
    )

}