import axios from 'axios';

export default {
    request(opts = {}) {
        return new Promise((resolve, reject) => {

            opts.data = opts.data || {}
            const newData = Object.assign(opts.data,{code:localStorage.getItem("code")})
            
            axios.post(opts.url, newData)
                .then((res) => {
                    resolve(res.data)
                }, err => {
                    console.log(err)
                    reject(err);
                })
        })
    }
}
