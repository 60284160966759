import React, { useEffect, useState } from 'react';
import { Button, Layout, Modal, Form, Input, Table, message, Space } from 'antd';
import { useNavigate } from "react-router-dom";
import Request from '../common/Request'
const { TextArea } = Input;
export default function (props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [gits, setGits] = useState([]);
    const [current, setCurrent] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [createProjectModal, setCreateProjectModal] = useState(false)
    const [showLogModal, setShowLogModal] = useState(false)
    const [logInfo, setlogInfo] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        getData()
    }, [current, pageSize])

    function getData() {
        Request.request({
            url: '/skynet/schedule/index?fdb',
            data: {
                page: current,
                pageSize
            }
        }).then(res => {
            if (res.code !== 0) {
                navigate("/login");
            }
            setGits(res.result.datas)
            setTotal(res.result.total)
        })
    }

    function createProject(row) {
        Request.request({
            url: '/skynet/schedule/create?fdb',
            data: row
        }).then(res => {
            if (res.code) {
                // navigate("/login");
            }
            getData()
            setCreateProjectModal(false)
        })
    }
    function startOrStopSchedule(record) {
        Request.request({
            url: '/skynet/schedule/start-or-stop',
            data: { "id": record._id }
        }).then(res => {
            getData();
            messageApi.open({
                type: 'success',
                content: '提交成功！',
            });
        })
    }
    function deleteSchedule(record) {
        Request.request({
            url: '/skynet/schedule/remove',
            data: { "id": record._id }
        }).then(res => {
            getData();
            messageApi.open({
                type: 'success',
                content: '提交成功！',
            });
        })
    }
    function runSchedule(record) {
        Request.request({
            url: '/skynet/schedule/run?fdb',
            data: { "id": record._id }
        }).then(res => {
            getData();
            messageApi.open({
                type: 'success',
                content: '提交成功！',
            });
        })
    }
    function logSchedule(record) {
        Request.request({
            url: '/skynet/schedule/showlog',
            data: { "id": record._id }
        }).then(res => {
            setlogInfo(res.result.content)
            setShowLogModal(true)
        })
    }
    function cleanLog(record) {
        Request.request({
            url: '/skynet/schedule/cleanlog',
            data: { "id": record._id }
        }).then(res => {
            messageApi.open({
                type: 'success',
                content: '提交成功！',
            });
        })
    }
    return (
        <>{contextHolder}
        <Layout style={{ background: 'white' }}>
            <Space>
                <Button onClick={() => { setCreateProjectModal(true) }} type="primary" >新增</Button>
            </Space>
            <Table rowKey="_id" dataSource={gits} style={{ marginTop: '20px' }}
                pagination={{
                    current,
                    pageSize,
                    total,
                    position: ['bottomCenter'],
                    onChange: (current, pageSize) => {
                        setCurrent(current)
                        setPageSize(pageSize)
                    },
                }}
                columns={[
                    {
                        title: '计划任务',
                        render: (_, record) => {
                            return <>{record.project_id}<br/>{record.schedule_name}</>
                        }
                    },
                    {
                        title: 'command',
                        dataIndex: 'command'
                    },
                    {
                        title: '触发时间',
                        dataIndex: 'trigger_time'
                    },
                    {
                        title: '状态',
                        dataIndex: 'status'
                    },
                    {
                        title: '日志',
                        render: (_, record) => {
                            return <><TextArea value={JSON.stringify(record.log)} style={{height:'150px'}}></TextArea></>
                        }
                    },
                    {
                        title: '操作',
                        render: (_, record) => {
                            return <Space direction='vertical'>
                                <Button onClick={() => { startOrStopSchedule(record) }}>开关计划</Button>
                                <Button onClick={() => { deleteSchedule(record) }}>删除计划</Button>
                                <Button onClick={() => { runSchedule(record) }}>运行一次</Button>
                            </Space>
                        }
                    }
                ]}>
            </Table>

            <div>
                <img src="/static/cron.png" alt="" />
                <pre>
                    {`在以上各个字段中，还可以使用以下特殊字符：
星号（*）：代表所有可能的值，例如month字段如果是星号，则表示在满足其它字段的制约条件后每月都执行该命令操作。
逗号（,）：可以用逗号隔开的值指定一个列表范围，例如，“1,2,5,7,8,9”
中杠（-）：可以用整数之间的中杠表示一个整数范围，例如“2-6”表示“2,3,4,5,6”
正斜线（/）：可以用正斜线指定时间的间隔频率，例如“0-23/2”表示每两小时执行一次。同时正斜线可以和星号一起使用，例如*/10，如果用在minute字段，表示每十分钟执行一次。`}</pre>
            </div>
            <Modal open={createProjectModal} closable={false} footer={null} >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                    onFinish={createProject}
                >
                    <Form.Item
                        label="计划任务名称"
                        name="schedule_name"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="域名"
                        name="domain"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="触发时间"
                        name="trigger_time"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="command"
                        name="command"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Space>
                            <Button onClick={() => setCreateProjectModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal open={showLogModal} closable={false} footer={null} >
                <TextArea value={logInfo}></TextArea>
                <Button onClick={()=>{setShowLogModal(false)}}>确定</Button>
            </Modal>
        </Layout>
        </>
    )

}