import { Routes, Route, Navigate } from 'react-router-dom'
import Home from './common/Home';
import Login from './pages/Login';
import Wbs from './pages/Wbs';
import Project from './pages/Project';
import Schedule from './pages/Schedule';
import Storage from './pages/Storage';
import SlowQuery from './pages/SlowQuery';
import WbsProjects from './pages/WbsProjects';
import Ssl from './pages/Ssl';

function App() {
  return (
    <Routes>
      <Route exact path='/login' element={<Login />} />

      <Route path='*' element={<Navigate to="/login" />} />

      <Route exact path='/home' element={<Home />} >
        <Route exact path='/home/wbs' element={<Wbs />} />
        <Route exact path='/home/project' element={<Project />} />
        <Route exact path='/home/schedule' element={<Schedule />} />
        <Route exact path='/home/storage' element={<Storage />} />
        <Route exact path='/home/slow-query' element={<SlowQuery />} />
        <Route exact path='/home/wbs-projects' element={<WbsProjects />} />
        <Route exact path='/home/ssl' element={<Ssl />} />
      </Route>
    </Routes>
  );
}

export default App;